
.company-name{
    cursor: pointer;
    transition: font-size 1s;
}

.company-name:hover{
    font-size: 1.3rem;
}

.inactive-company{
    text-decoration: underline;
}

.job-title{
    font-weight: bold;
    font-size: 1.25rem;
    margin: 10px 0px;
}

.job-title-date{
    font-size: 1.05rem;
}

.job-details{
}
