.App{
    height: 100%;
}

.router{
    height: 100%;
}

.vertical-align{
    height: 100%;
    display: flex;
    align-items: center;
}

.section-head-7{
    margin-bottom: 7rem;
}
.section-head-2{
    margin-bottom: 2rem;
}

.row{
    margin: 0px !important;
    padding: 0px !important;
}


.setOpaque{
    background: linear-gradient(#262b2f, transparent);
    z-index: 100;
}
@media (max-width: 576px){
}