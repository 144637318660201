.navbar{
    border: none;
    color: white;
    z-index: 100;
}

.nav-item{
    font-size: 1.05rem;
    transition: all 0.5s;
}

.nav-item:hover{
    font-size: 1.2rem;
    font-weight: bold;
}